import { createStore } from 'vuex'
import eventBus from '../EventBus'
import SessionService from '../services/SessionService'
import _ from 'lodash'
import constants from '@/constants'
import { signOut } from '@/services/clients/firebase'
import { getPayPeriodDateRange, getPickerDatesForDatetimeRange } from '@/utils/PayPeriod'

const { loginServer } = constants()

const store = createStore({
  state () {
    return {
      route: null,
      pendingRoute: null,
      // Track original subscription state, since we convert in-app
      // to free trial in the middle of the billing wizard before payment.
      // It's not really accurate when signing up for a new subscription when
      // the state loaded with a different subscription. But it's good enough.
      originalSubscriptionState: null,
      settingsLoaded: false,
      organizationId: null,
      organizationName: null,
      organizationActive: false,
      orgUserId: null,
      contactSuppressions: null,
      avatar: null,
      subscriptionState: null,
      billingCustomerId: null,
      expireDate: null,
      features: [],
      maxDevices: 0,
      maxUsers: 0,
      maxUsersIsWorkersOnly: false,
      adminPersonalDeviceModeAllowed: false,
      activeDevices: 0,
      activeUsers: 0,
      activeWorkers: 0,
      timezone: null,
      geoEnabled: false,
      bgGeoEnabled: false,
      enableSubmitMissedPunch: false,
      qrCodeEnabled: false,
      nfcTagEnabled: false,
      applyShiftOverlapTo: null,
      payPeriodType: null,
      payPeriodStarts: null,
      usePayClass: null,
      timeOffEnabled: false,
      timeOffAccrualEnabled: false,
      payrollId: null,
      enablePayRun: false,
      enableNetPay: false,
      costingMode: null,
      jobRequired: null,
      shiftMode: null,
      openShiftType: null,
      openShiftStartsAt: null,
      openShiftMaxDuration: null,
      maxExtendShiftEarly: null,
      maxExtendShiftLate: null,
      punchCodeMode: null,
      permissions: [],
      employeeSensitiveFields: [],
      adminAllowLabels: null,
      requireJobAccess: false,
      canRestrictJobAccessByUser: false,
      entryStateEnabled: false,
      entryStateExportPrompt: false,
      credentialsEnabled: false,
      fatalError: false,
      fatalErrorTitle: null,
      fatalErrorMsg: null,
      maxDocumentsPerUser: 0,
      maxDocumentFileSize: 2 * Math.pow(10, 6), // 2 MB
      disciplineEnabled: false,
      scheduledReportMaxAdditionalRecipients: 5,
      enableBriefings: false,
      distributor: null,
      maxCrossIndexFields: 5,
      maxCustomFieldsPerModel: 15,
      maxCustomItemsPerField: 500
    }
  },
  getters: {
    noConsoleAccess: state => _.isEqual(state.permissions, ['setup_clocks']),
    scheduleEnabled: (state, getters) => getters.hasScheduleFeature && !!state.shiftMode && state.shiftMode !== 'default',
    hasCredentialFeature: state => state.features.includes('credential'),
    hasDocumentFeature: state => state.features.includes('document'),
    hasEntryStateFeature: state => state.features.includes('entry_state'),
    hasIntegrationFeature: state => state.features.includes('integration'),
    hasNotificationFeature: state => state.features.includes('notification'),
    hasScheduleFeature: state => state.features.includes('schedule'),
    hasNetPayFeature: state => state.features.includes('netpay'),
    hasPayRunFeature: state => state.features.includes('pay_run'),
    hasPayrollFeature: state => state.features.includes('payroll'),
    hasTimeOffFeature: state => state.features.includes('pto'),
    hasTimeOffAccrualFeature: state => state.features.includes('time_off_accrual'),
    hasCostingFeature: state => state.features.includes('job_costing'),
    hasPunchCodeFeature: state => state.features.includes('punch_codes'),
    hasBriefingFeature: state => state.features.includes('briefing'),
    entryStateEnabled: (state, getters) => getters.hasEntryStateFeature && state.entryStateEnabled,
    geoEnabled: (state, getters) => getters.hasGeoFeature && state.geoEnabled,
    bgGeoEnabled: (state, getters) => getters.geoEnabled && getters.hasBgGeoFeature && !!state.bgGeoEnabled,
    netPayEnabled: (state, getters) => getters.hasNetPayFeature && state.enableNetPay,
    payRunEnabled: (state, getters) => getters.hasPayRunFeature && state.enablePayRun,
    payrollEnabled: (state, getters) => getters.hasPayrollFeature && state.usePayClass,
    timeOffEnabled: (state, getters) => getters.hasTimeOffFeature && state.timeOffEnabled,
    timeOffAccrualEnabled: (state, getters) => getters.hasTimeOffAccrualFeature && state.timeOffAccrualEnabled,
    costingEnabled: (state, getters) => getters.hasCostingFeature && !!state.costingMode,
    enhancedCostingEnabled: (state, getters) => getters.costingEnabled && state.costingMode === 'enhanced',
    jobCategoryEnabled: (state, getters) => getters.enhancedCostingEnabled && state.features.includes('job_category'),
    punchCodesEnabled: (state, getters) => getters.hasPunchCodeFeature && state.punchCodeMode !== 'disabled',
    credentialsEnabled: (state, getters) => getters.hasCredentialFeature && !!state.credentialsEnabled,
    hasBgGeoFeature: state => state.features.includes('bg_geo'),
    hasCustomFieldFeature: state => state.features.includes('customfield'),
    hasFaceFeature: state => state.features.includes('face'),
    hasGeoFeature: state => state.features.includes('geo'),
    needsSetup: (state, getters) => getters.isInApp,
    notInApp: state => state.settingsLoaded && state.subscriptionState !== 'in_app',
    // TODO: isFreeTrial is false negative in case where user aborted paid upgrade.
    // TODO: We may need an org boolean field whether user ever had paid subscription.
    isInApp: state => state.settingsLoaded && state.subscriptionState === 'in_app',
    isFreeTrial: state =>
      state.settingsLoaded &&
      (state.subscriptionState === 'trial' || (state.subscriptionState === 'expired' && !state.billingCustomerId)),
    isPaidPlan: state => state.settingsLoaded && state.subscriptionState !== 'trial',
    orgReady: (state, getters) => !getters.needsSetup && state.organizationActive,
    insideOrgNamespace: state => !!_.get(state.route, 'params.orgId'),
    inSignUpWizard: state => ['sign-up-wizard', 'inapp-sign-up-wizard'].includes(_.get(state.route, 'name')),
    isAccountOwner: state => state.permissions.includes('manage_subscription'),
    canAccessEmployeeSensitive: state => state.permissions.includes('access_employee_sensitive'),
    canAccessEmployeeSensitiveField: (state, getters) => field =>
      getters.canAccessEmployeeSensitive || !state.employeeSensitiveFields.includes(field),
    canEditAdministrators: state => state.permissions.includes('edit_org_users'),
    canEditCosting: state => state.permissions.includes('edit_jobs'),
    canEditSelf: state => state.permissions.includes('edit_self'),
    canEditDevices: state => state.permissions.includes('edit_clocks'),
    canEditWorkers: state => state.permissions.includes('edit_employees'),
    canEditOrgSettings: state => state.permissions.includes('edit_settings'),
    canEditPunchTimes: state => state.permissions.includes('edit_punches'),
    canEditPunchAttributes: state => state.permissions.includes('edit_punch_attributes'),
    canAccessManagePunches: (state, getters) => getters.canEditPunchTimes || getters.canEditPunchAttributes,
    canManageClockLogs: state => state.permissions.includes('manage_clock_logs'),
    canManagePayroll: state => state.permissions.includes('manage_payroll'),
    canManageSchedule: state => state.permissions.includes('manage_schedule'),
    canManageTimeOff: (state, getters) => state.permissions.includes('manage_pto') && getters.timeOffEnabled,
    canViewAudit: state => state.permissions.includes('view_audit'),
    canViewReports: state => state.permissions.includes('view_reports'),
    canViewWorkerTimeOffPolicy: (state, getters) =>
      getters.timeOffAccrualEnabled && getters.canAccessEmployeeSensitiveField('time_off_policy'),
    canViewWorkerPay: (state, getters) => getters.canAccessEmployeeSensitiveField('pay_rates'),
    payPeriodSettings: state => payClass => ({
      payPeriodType: payClass ? payClass.payPeriod : state.payPeriodType,
      payPeriodStarts: payClass ? payClass.payPeriodStartsOn : state.payPeriodStarts
    }),
    payPeriodDateRange: (state, getters) => (whichPeriod, refDate, payClass) => {
      const payPeriodSettings = getters.payPeriodSettings(payClass)
      return getPayPeriodDateRange(
        state.timezone,
        payPeriodSettings.payPeriodType,
        payPeriodSettings.payPeriodStarts,
        whichPeriod,
        refDate)
    },
    payPeriodRangeAsPickerDates: (state, getters) => (whichPeriod, refDate, payClass) => {
      const range = getters.payPeriodDateRange(whichPeriod, refDate, payClass)
      return getPickerDatesForDatetimeRange(...range)
    },
    canRestrictJobAccessByUser: (state, getters) =>
      Boolean(getters.costingEnabled && state.canRestrictJobAccessByUser),
    requireJobAccess: (state, getters) =>
      Boolean(getters.canRestrictJobAccessByUser && state.requireJobAccess),
    entryStateExportPrompt: (state, getters) =>
      Boolean(getters.entryStateEnabled && state.entryStateExportPrompt),
    billableUserCount: state => state.maxUsersIsWorkersOnly ? state.activeWorkers : state.activeUsers,
    disciplineEnabled: state => state.features.includes('discipline') && !!state.disciplineEnabled,
    adminAllowAllLabels: state => _.isEmpty(state.adminAllowLabels),
    accessAllOrgUnits: state => _.isEmpty((state.adminAllowLabels || []).filter(label => label.includes(':orgunit:'))),
    accessAllJobs: (state, getters) => !getters.requireJobAccess && _.isEmpty((state.adminAllowLabels || []).filter(label => label.startsWith('work:job:') || label.startsWith('work:label:'))),
    userNotificationsEnabled: (state, getters, rootState) => getters.hasNotificationFeature || rootState.userProfile.userNotificationsEnabled,
    enableBriefings: (state, getters) => getters.hasBriefingFeature && !!state.enableBriefings,
    healthAndSafetyEnabled: (state, getters) => getters.credentialsEnabled || getters.enableBriefings,
    isByxbiOrg: state => state.distributor === 5685342286643200,
    canManageByxbi: (state, getters, rootState) => getters.isByxbiOrg && (rootState.userProfile.isAppAdmin || rootState.userProfile.isDistributorAdmin),
    hasPendingRoute: state => !!state.pendingRoute
  },
  actions: {
    async login (context, orgId) {
      try {
        await this.getters['jwt/idaasJwt']()
        context.commit('login', orgId)
      } catch (error) {
        if (!error.redirecting) {
          context.commit('fatalErrorDidOccur', {
            fatalErrorMsg: 'Was unable to login due to a network error.'
          })
        }
      }
    },
    logout () {
      signOut()
        .finally(() => { window.location.href = `${loginServer}/logout` })
    },
    async initSession (context, orgId) {
      try {
        context.commit('initSession', await SessionService.retrieveSettings(orgId))
      } catch (error) {
        // default error message
        let fatalErrorMsg = 'Was unable to load user settings due to a network error.'
        const response = error.response

        if (_.get(response, 'status') === 403) {
          fatalErrorMsg = 'You do not have permission to access this organization.'
        } else if (_.get(response, 'status') === 401 && _.get(response, 'data.detail')) {
          // example detail: Organization login requirement not met
          fatalErrorMsg = response.data.detail
        }

        context.commit('fatalErrorDidOccur', { fatalErrorMsg })
      }
    },
    updateSession (context, settings) {
      context.commit('updateSession', settings)
    },
    fatalErrorDidOccur (context, { fatalErrorTitle, fatalErrorMsg }) {
      context.commit('fatalErrorDidOccur', { fatalErrorTitle, fatalErrorMsg })
    },
    decrementActiveUserCount (context, wasWorker) {
      context.commit('updateSession', {
        activeUsers: context.state.activeUsers - 1,
        activeWorkers: context.state.activeWorkers - (wasWorker ? 1 : 0)
      })
    },
    setActiveUserCount (context, { activeUsers, activeWorkers }) {
      if (activeUsers !== context.state.activeUsers || activeWorkers !== context.state.activeWorkers) {
        context.commit('updateSession', { activeUsers, activeWorkers })
      }
    },
    setActiveDeviceCount (context, activeDevices) {
      if (activeDevices !== context.state.activeDevices) {
        context.commit('updateSession', { activeDevices })
      }
    }
  },
  mutations: {
    login (state, orgId) {
      this.dispatch('userProfile/load')
      this.dispatch('orgService/bootstrapOrg', orgId)
    },
    initSession (state, session) {
      Object.assign(state, session)

      // TODO: support switching org, or should we just reload page?
      state.originalSubscriptionState = state.subscriptionState
      state.settingsLoaded = true
      eventBus.emit('session-updated', session)
    },
    updateSession (state, session) {
      Object.assign(state, session)
      eventBus.emit('session-updated', state)
    },
    fatalErrorDidOccur (state, { fatalErrorTitle, fatalErrorMsg }) {
      Object.assign(state, {
        fatalError: true,
        fatalErrorTitle: fatalErrorTitle,
        fatalErrorMsg: fatalErrorMsg
      })
    },
    setRoute (state, route) {
      state.route = route
    },
    setPendingRoute (state, route) {
      state.pendingRoute = route
    }
  }
})

export default store
